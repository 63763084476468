const actions = {
  GET_REF: "GET_REF",
  GET_REF_SUCCESS: "GET_REF_SUCCESS",
  GET_REF_FAILED: "GET_REF_FAILED",
  APPROVE: "APPROVE",
  APPROVE_SUCCESS: "APPROVE_SUCCESS",
  APPROVE_FAILED: "APPROVE_FAILED",

  getUserDetails: (payload) => ({
    type: actions.GET_REF,
    payload,
  }),
  approve: (payload) => ({
    type: actions.APPROVE,
    payload,
  }),
  
  
};
export default actions;
