import actions from "./actions";

const initialState = {
  error: false,
  loading: false,
  userDetails: "",
};

export default function RefReview(state = initialState, action) {
  switch (action.type) {
    case actions.GET_REF:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_REF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userDetails: action.payload,
      };
      case actions.GET_REF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        userDetails: action.payload,
      };
    case actions.GET_REF_FAILED ||
      actions.APPROVE_FAILED:
      return {
        ...state,
        error: action.payload || "error",
        loading: false,
      };
    case actions.APPROVE:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
