const actions = {
  GET_REWARDS: "GET_REWARDS",
  GET_REWARDS_SUCCESS: "GET_REWARDS_SUCCESS",
  GET_REWARDS_FAILED: "GET_REWARDS_FAILED",
  SET_REWARDS_STATUS: "SET_REWARDS_STATUS",
  SET_REWARDS_STATUS_SUCCESS: "SET_REWARDS_STATUS_SUCCESS",
  SET_REWARDS_STATUS_FAILED: "SET_REWARDS_STATUS_FAILED",

  getRewards: (payload) => ({
    type: actions.GET_REWARDS,
    payload: payload,
  }),
  setRewardStatus: (payload) => ({
    type: actions.SET_REWARDS_STATUS,
    payload: payload,
  }), 
};
export default actions;
