import actions from "./actions";
let initState = {
  loading: false, // User's data - Role and Redirection check
  errorMessage: null,
  data: [],
};
export default function rewards(state = initState, action) {
  switch (action.type) {
    case actions.GET_REWARDS:
      return {
        ...initState,
        loading: true,
      };
    case actions.GET_REWARDS_SUCCESS:
      return {
        ...initState,
        data: action.payload,
      };
    case actions.GET_REWARDS_FAILED:
      return {
        ...initState,
        errorMessage: action.payload,
      };
    case actions.SET_REWARDS_STATUS:
      return {
        ...state,
        loading: true,
      };
    case actions.SET_REWARDS_STATUS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
      case actions.SET_REWARDS_STATUS_SUCCESS:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
