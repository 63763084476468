import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { message } from "antd";
import {
  getUserRequest,
  approveRequest,
  skipRequest,
} from "@iso/lib/apiHelpers/refReviewReq";
import actions from "./actions";

export function* getUserEffect() {
  yield takeEvery(
    [actions.GET_REF, actions.APPROVE_SUCCESS],
    function* ({ payload }) {
      try {
        const response = yield call(getUserRequest, payload);
        console.log(response);
        if (response.profile) {
          yield put({
            type: actions.GET_REF_SUCCESS,
            payload: response,
          });
          message.success("New User Detail is Fetched");
        } else {
          yield put({
            type: actions.GET_REF_FAILED,
            payload: response,
          });
          message.error("No Data found");
        }
      } catch (error) {
        console.log(error);
        yield put({
          type: actions.GET_REF_FAILED,
          payload: error.message,
        });
        message.error(error.message);
      }
    }
  );
}
export function* approveRefEffect() {
  yield takeEvery(actions.APPROVE, function* ({ payload }) {
    try {
      let response;
      if (payload.type === undefined || payload.type === "reject") {
        response = yield call(skipRequest, payload);
      } else {
        response = yield call(approveRequest, payload);
      }
      console.log(response, "aapprove");
      if (response.length !== 0) {
        yield put({
          type: actions.APPROVE_SUCCESS,
        });
        message.success("Approved Successfuly");
      } else {
        yield put({
          type: actions.APPROVE_FAILED,
          payload: "Error Occured",
        });
        message.error("Error Occured");
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.APPROVE_FAILED,
        payload: "Error Occured",
      });
      message.error("Error Occured");
    }
  });
}




export default function* rootSaga() {
  yield all([
    fork(getUserEffect),
    fork(approveRefEffect),
  ]);
}
