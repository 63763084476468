import { axiosInstance } from "./axiosInstance";

export const getRewardsReq = (payload) => {
  return axiosInstance({
    method: "POST",
    url: "/rewards/pending",
    data: {
      ...payload,
    },
  });
};

export const setRewardsReq = (payload) => {

  console.log(payload, "setRewardsReq");
  return axiosInstance({
    method: "POST",
    url: "/rewards/update",
    data: {
      ...payload,
    },
  });
};
