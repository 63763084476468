import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import ReduxSagaFirebase from 'redux-saga-firebase';
import isoConfig from '@iso/config/firebase.config';
// Example if anyone want to use different config for production and development using .env

// const prodConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId:process.env.REACT_APP_APP_ID,
  // measurementId: process.env.REACT_APP_MEASUREMENT_ID
// };

// const devConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId:process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID
// };

// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
!firebase.apps.length ? firebase.initializeApp(isoConfig) : firebase.app();

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(isoConfig)
  : firebase.app();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const rsf = new ReduxSagaFirebase(firebaseApp);
export default firebase;
