export default {
  apiKey: "AIzaSyDngbT2o0Z9-cBB15gOSjcTXul4ryKqhGU",
  authDomain: "live-quize-de18c.firebaseapp.com",
  databaseURL: "https://live-quize-de18c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "live-quize-de18c",
  storageBucket: "live-quize-de18c.appspot.com",
  messagingSenderId: "626840264112",
  appId: "1:626840264112:web:96d46661043dee6a780a75",
  measurementId: "G-SKNZCKVVH4"
};
