import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import { message, notification } from "antd";
import history from "../../library/helpers/history";
import authActions from "../app/actions";
import { getRewardsReq, setRewardsReq } from "../../library/apiHelpers/rewardsReq";
export function* postRewardsEffect() {
  yield takeEvery(actions.GET_REWARDS, function* ({ payload }) {
    try {
      const response = yield call(getRewardsReq, payload);
      if (response) {
        yield put({
          type: actions.GET_REWARDS_SUCCESS,
          payload: response,
        });
      } else {
        yield put({
          type: actions.GET_REWARDS_FAILED,
          payload: response,
        });
      }
    } catch (error) {
      console.log(error, "err");
      yield put({
        type: actions.GET_REWARDS_FAILED,
        payload: error.message,
      });
    }
  });
}

export function* setRewardStatusEffect() {
  yield takeEvery(actions.SET_REWARDS_STATUS, function* ({ payload }) {
    
    try {
      const response = yield call(setRewardsReq, payload);
      console.log(response, "setRewardStatusEffect");
      if (response.message === "success") {
        yield put({
          type: actions.SET_REWARDS_STATUS_SUCCESS,
          payload: response,
        });
        yield put({
          type: actions.GET_REWARDS,
          payload: {queryOption: payload.queryOption},
        });
        
      } else {
        yield put({
          type: actions.SET_REWARDS_STATUS_FAILED,
          payload: response,
        });
      }
    } catch (error) {
      console.log(error, "setRewardStatusEffect err");
      yield put({
        type: actions.SET_REWARDS_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(postRewardsEffect), fork(setRewardStatusEffect)]);
}
