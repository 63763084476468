import { axiosInstance } from "./axiosInstance";

// Login
export const getUserRequest = (payload) => {
  return axiosInstance({
    method: "Post",
    url: `refReview/getnew`,
    data: {
      ...payload
    }
  });
};
export const approveRequest = (payload) => {
  console.log(payload);
  return axiosInstance({
    method: "POST",
    url: `refReview/approve`,
    data: JSON.stringify(payload),
  });
};

export const skipRequest = (payload) => {
  console.log(payload);
  return axiosInstance({
    method: "POST",
    url: `refReview/skip`,
    data: JSON.stringify(payload),
  });
};

